import type { JSX, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

import { isNotEmptyString } from '@import-io/typeguards';
import Space from 'antd/lib/space';
import Text from 'antd/lib/typography/Text';

import PageLayout from 'common/components/layout/PageLayout';
import Logo from 'common/components/Logo';

import styles from './LogoPage.module.scss';

export interface LogoPageProps {
  readonly header?: string;
  readonly subContent?: JSX.Element;
  readonly title?: string;
}

const contentClass = `flex flex-column justify-center align-center full-height ${styles.content}`;

const LogoPage = ({ children, subContent, header, title }: PropsWithChildren<LogoPageProps>): JSX.Element => (
  <PageLayout>
    {isNotEmptyString(title) ? (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    ) : null}
    <Space className="flex flex-column" direction="vertical" size="middle">
      <Space align="center" className="full-width" direction="vertical">
        <Logo />
        {isNotEmptyString(header) ? <Text>{header}</Text> : null}
      </Space>
      <div className={contentClass}>{children}</div>
      {subContent ? subContent : null}
    </Space>
  </PageLayout>
);

export default LogoPage;
