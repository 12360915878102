import type { ThemeConfig } from 'antd/lib/config-provider/context';

export const BORDER_COLOR = 'rgba(0, 0, 0, 0.3)';
export const PLACEHOLDER_COLOR = 'rgba(0, 0, 0, 0.5)';
export const PRIMARY_COLOR = '#3e0e87';
export const WHITE_COLOR = '#FFF';

export const theme: ThemeConfig = {
  components: {
    Button: {
      colorPrimary: PRIMARY_COLOR,
      colorPrimaryActive: '#350c73',
      colorPrimaryHover: '#5513b8',
      controlHeight: 40,
      fontSizeLG: 14,
      colorBorder: '#d2bdef',
      colorText: '#5513b8',
    },
    Input: {
      colorBorder: BORDER_COLOR,
      colorPrimaryBorderHover: PRIMARY_COLOR,
      colorTextPlaceholder: PLACEHOLDER_COLOR,
      lineHeight: 2,
    },
    Layout: {
      bodyBg: '#f0f2f5',
      headerBg: 'transparent',
    },
    Select: {
      colorBorder: BORDER_COLOR,
      colorPrimaryBorderHover: PRIMARY_COLOR,
      colorTextPlaceholder: PLACEHOLDER_COLOR,
      controlHeight: 36,
      lineHeight: 2,
    },
  },
  token: {
    colorBgBase: WHITE_COLOR,
    colorBorder: PRIMARY_COLOR,
    colorPrimaryText: '#303034',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: 14,
    lineHeight: 1.4285714,
  },
};
