import type { JSX } from 'react';
import { Link } from 'react-router-dom';

import { ROOT_URL } from 'features/routes/routes.constants';

const Logo = (): JSX.Element => (
  <Link to={ROOT_URL}>
    <svg className="siteLogo__svg" fill="none" viewBox="0 0 760 151" width="200" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.4112 75.5009L76.3084 111.225L112.213 75.5079L76.3084 39.7834L40.4112 75.5009Z" fill="#772CE8" />
      <path d="M0.510304 35.7175L36.4075 71.4419L72.3118 35.7244L36.4075 0L0.510304 35.7175Z" fill="#772CE8" />
      <path d="M0.510304 115.217L36.4075 150.942L72.3118 115.224L36.4075 79.5L0.510304 115.217Z" fill="#772CE8" />
      <path
        d="M187.549 119.059V43.7991H212.399L213.819 48.7691H214.529C215.949 47.1598 218.173 45.6924 221.203 44.3671C224.327 43.0418 227.782 42.3791 231.569 42.3791C235.639 42.3791 239.331 43.1838 242.645 44.7931C245.958 46.4024 248.419 48.2011 250.029 50.1891H250.739C252.348 48.2011 255.046 46.4024 258.833 44.7931C262.62 43.1838 267.021 42.3791 272.039 42.3791C277.246 42.3791 281.884 43.6098 285.955 46.0711C290.025 48.4378 293.197 51.8931 295.469 56.4371C297.836 60.8864 299.019 66.1404 299.019 72.1991V119.059H271.329V77.8791C271.329 75.1338 270.666 73.0511 269.341 71.6311C268.11 70.1164 266.406 69.3591 264.229 69.3591C262.052 69.3591 260.3 70.1164 258.975 71.6311C257.744 73.0511 257.129 75.1338 257.129 77.8791V119.059H229.439V77.8791C229.439 75.1338 228.776 73.0511 227.451 71.6311C226.22 70.1164 224.516 69.3591 222.339 69.3591C220.161 69.3591 218.41 70.1164 217.085 71.6311C215.854 73.0511 215.239 75.1338 215.239 77.8791V119.059H187.549Z"
        fill="black"
      />
      <path
        d="M308.265 147.459V43.7991H333.115L334.535 48.7691H335.245C336.665 47.1598 339.032 45.6924 342.345 44.3671C345.659 43.0418 349.445 42.3791 353.705 42.3791C359.859 42.3791 365.539 44.0358 370.745 47.3491C375.952 50.5678 380.07 55.1591 383.099 61.1231C386.223 67.0871 387.785 73.8558 387.785 81.4291C387.785 89.0024 386.223 95.7711 383.099 101.735C380.07 107.699 375.952 112.338 370.745 115.651C365.539 118.87 359.859 120.479 353.705 120.479C349.919 120.479 346.463 119.958 343.339 118.917C340.31 117.876 338.085 116.74 336.665 115.509H335.955V147.459H308.265ZM348.025 94.9191C351.339 94.9191 354.179 93.6884 356.545 91.2271C358.912 88.7658 360.095 85.4998 360.095 81.4291C360.095 77.3584 358.912 74.0924 356.545 71.6311C354.179 69.1698 351.339 67.9391 348.025 67.9391C344.712 67.9391 341.872 69.1698 339.505 71.6311C337.139 74.0924 335.955 77.3584 335.955 81.4291C335.955 85.4998 337.139 88.7658 339.505 91.2271C341.872 93.6884 344.712 94.9191 348.025 94.9191Z"
        fill="black"
      />
      <path
        d="M431.789 120.479C424.31 120.479 417.541 118.775 411.483 115.367C405.424 111.864 400.643 107.178 397.141 101.309C393.733 95.3451 392.029 88.7184 392.029 81.4291C392.029 74.1398 393.733 67.5604 397.141 61.6911C400.643 55.7271 405.424 51.0411 411.483 47.6331C417.541 44.1304 424.31 42.3791 431.789 42.3791C439.267 42.3791 446.036 44.1304 452.095 47.6331C458.153 51.0411 462.887 55.7271 466.295 61.6911C469.797 67.5604 471.549 74.1398 471.549 81.4291C471.549 88.7184 469.797 95.3451 466.295 101.309C462.887 107.178 458.153 111.864 452.095 115.367C446.036 118.775 439.267 120.479 431.789 120.479ZM431.789 94.9191C435.102 94.9191 437.942 93.6884 440.309 91.2271C442.675 88.7658 443.859 85.4998 443.859 81.4291C443.859 77.3584 442.675 74.0924 440.309 71.6311C437.942 69.1698 435.102 67.9391 431.789 67.9391C428.475 67.9391 425.635 69.1698 423.269 71.6311C420.902 74.0924 419.719 77.3584 419.719 81.4291C419.719 85.4998 420.902 88.7658 423.269 91.2271C425.635 93.6884 428.475 94.9191 431.789 94.9191Z"
        fill="black"
      />
      <path
        d="M478.699 119.059V43.7991H503.549L504.969 48.7691H505.679C506.815 47.5384 508.519 46.4024 510.791 45.3611C513.063 44.3198 515.619 43.7991 518.459 43.7991H531.239V69.3591H518.459C514.483 69.3591 511.453 70.4478 509.371 72.6251C507.383 74.7078 506.389 77.8791 506.389 82.1391V119.059H478.699Z"
        fill="black"
      />
      <path
        d="M575.648 119.059C567.222 119.059 560.832 117.024 556.478 112.953C552.218 108.788 550.088 102.776 550.088 94.9191V69.3591H538.728V43.7991H550.088V21.0791H577.778V43.7991H594.108V69.3591H577.778V88.5291C577.778 90.3278 578.156 91.6058 578.914 92.3631C579.671 93.1204 580.949 93.4991 582.748 93.4991H594.108V119.059H575.648Z"
        fill="black"
      />
      <path
        d="M634.572 104.149C634.572 112.384 627.897 119.059 619.662 119.059V119.059C611.428 119.059 604.752 112.384 604.752 104.149V104.149C604.752 95.9145 611.428 89.2391 619.662 89.2391V89.2391C627.897 89.2391 634.572 95.9145 634.572 104.149V104.149Z"
        fill="black"
      />
      <path
        d="M719.733 120.479C712.255 120.479 705.486 118.775 699.427 115.367C693.369 111.864 688.588 107.178 685.085 101.309C681.677 95.3451 679.973 88.7184 679.973 81.4291C679.973 74.1398 681.677 67.5604 685.085 61.6911C688.588 55.7271 693.369 51.0411 699.427 47.6331C705.486 44.1304 712.255 42.3791 719.733 42.3791C727.212 42.3791 733.981 44.1304 740.039 47.6331C746.098 51.0411 750.831 55.7271 754.239 61.6911C757.742 67.5604 759.493 74.1398 759.493 81.4291C759.493 88.7184 757.742 95.3451 754.239 101.309C750.831 107.178 746.098 111.864 740.039 115.367C733.981 118.775 727.212 120.479 719.733 120.479ZM719.733 94.9191C723.047 94.9191 725.887 93.6884 728.253 91.2271C730.62 88.7658 731.803 85.4998 731.803 81.4291C731.803 77.3584 730.62 74.0924 728.253 71.6311C725.887 69.1698 723.047 67.9391 719.733 67.9391C716.42 67.9391 713.58 69.1698 711.213 71.6311C708.847 74.0924 707.663 77.3584 707.663 81.4291C707.663 85.4998 708.847 88.7658 711.213 91.2271C713.58 93.6884 716.42 94.9191 719.733 94.9191Z"
        fill="black"
      />
      <path d="M670.586 119.059H642.896V62.5591L670.586 44.6841V119.059Z" fill="black" />
      <path d="M642.897 38.3337V55.5916L670.507 38.3371L642.897 21.0792V38.3337Z" fill="black" />
      <path d="M176.397 119.059H148.707V62.5591L176.397 44.6841V119.059Z" fill="black" />
      <path d="M148.706 38.3337V55.5916L176.316 38.3371L148.706 21.0792V38.3337Z" fill="black" />
    </svg>
  </Link>
);

export default Logo;
