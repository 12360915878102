import type { JSX } from 'react';
import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes as ReactRoutes, useSearchParams } from 'react-router-dom';

import { authApi } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import { useQuery } from '@tanstack/react-query';

import { EMPTY_OBJECT } from 'common/common.constants';
import LoadingPage from 'common/components/LoadingPage';
import * as routesConstants from 'features/routes/routes.constants';
import type { RouteParams } from 'features/routes/routes.types';
import { redirectToApp } from 'features/routes/routes.utils';

const Login = lazy(() => import('features/login/Login'));
const ResetPassword = lazy(() => import('features/password/ResetPassword'));
const ForgotPassword = lazy(() => import('features/password/ForgotPassword'));
const MagicLinkSignup = lazy(() => import('features/signup/MagicLinkSignup'));
const ConfirmSignup = lazy(() => import('features/signup/ConfirmSignup'));

const userQueryKey = ['user'];
const loadingPage = <LoadingPage />;
const redirectToRoot = <Navigate to={routesConstants.ROOT_URL} />;
const ParamsContext = routesConstants.ParamsContext;
const userRequest = () => authApi.currentUser();

const Routes = (): JSX.Element => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [routeParams, setRouteParams] = useState<RouteParams>(EMPTY_OBJECT);
  const [params] = useSearchParams();
  const { redirect } = routeParams;

  const onSuccess = useCallback(() => {
    if (!window.location.pathname.includes(routesConstants.RESET_PASSWORD_URL)) {
      setIsAuthorized(true);
    }
  }, []);

  const { isLoading } = useQuery({
    onSuccess: onSuccess,
    queryFn: userRequest,
    queryKey: userQueryKey,
    staleTime: Infinity,
  });

  useEffect(() => {
    const searchParams = Object.fromEntries([...params]);
    setRouteParams(searchParams);
  }, [params]);

  useEffect(() => {
    if (isAuthorized) {
      redirectToApp(redirect);
    }
  }, [isAuthorized, redirect]);

  return isLoading || !isPresent(routeParams) ? (
    loadingPage
  ) : (
    <ParamsContext.Provider value={routeParams}>
      <Suspense fallback={loadingPage}>
        <ReactRoutes>
          <Route element={<Login />} path={routesConstants.ROOT_URL} />
          <Route element={<ResetPassword />} path={routesConstants.RESET_PASSWORD_URL} />
          <Route element={<ForgotPassword />} path={routesConstants.FORGOT_PASSWORD_URL} />
          <Route element={<MagicLinkSignup />} path={routesConstants.MAGIC_URL} />
          <Route element={<ConfirmSignup />} path={routesConstants.CONFIRM_SIGNUP_URL} />
          <Route element={redirectToRoot} path="*" />
        </ReactRoutes>
      </Suspense>
    </ParamsContext.Provider>
  );
};

export default Routes;
