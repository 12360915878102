import type { JSX } from 'react';

import PageLayout from 'common/components/layout/PageLayout';

interface ErrorPageProps {
  readonly error: string;
}

const ErrorPage = ({ error }: ErrorPageProps, error1: string = error): JSX.Element => <PageLayout>{error1}</PageLayout>;

export default ErrorPage;
