import type { JSX } from 'react';

import Space from 'antd/lib/space';

import styles from './Footer.module.scss';

const IMPORT_URL = 'https://import.io';

const PRIVACY_URL = `${IMPORT_URL}/privacy`;

const TERMS_URL = `${IMPORT_URL}/terms-conditions`;

const Footer = (): JSX.Element => (
  <Space className="full-width justify-between">
    <Space size="middle">
      <a className={styles.link} href={TERMS_URL} rel="noreferrer" target="_blank">
        Terms and conditions
      </a>
      <a className={styles.link} href={PRIVACY_URL} rel="noreferrer" target="_blank">
        Privacy
      </a>
    </Space>
    <a className={styles.link} href={IMPORT_URL} rel="noreferrer" target="_blank">
      import.io
    </a>
  </Space>
);

export default Footer;
