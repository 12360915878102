import type { JSX } from 'react';

import Spin from 'antd/lib/spin';

import LogoPage from 'common/components/LogoPage';

const LoadingPage = (): JSX.Element => (
  <LogoPage>
    <Spin />
  </LogoPage>
);

export default LoadingPage;
