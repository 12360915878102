import { createContext } from 'react';

import { EMPTY_OBJECT } from 'common/common.constants';
import type { RouteParams } from 'features/routes/routes.types';

export const ROOT_URL = '/';
/**
 * Get a new link to email to reset the password
 */
export const FORGOT_PASSWORD_URL = '/reset-password';
/**
 * Check reset token and set a new password
 */
export const RESET_PASSWORD_URL = '/send-new-password';
/**
 * Check signup token and complete registration
 */
export const CONFIRM_SIGNUP_URL = '/confirm-signup';
/**
 * Create a new user and send a confirmation link to email
 */
export const MAGIC_URL = '/magic-link';

export const ParamsContext = createContext<RouteParams>(EMPTY_OBJECT);
