import type { JSX } from 'react';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AntdApp from 'antd/lib/app';
import ConfigProvider from 'antd/lib/config-provider';
import message from 'antd/lib/message';

import { DEFAULT_MESSAGE_DURATION } from 'common/common.constants';
import ErrorBoundary from 'common/components/ErrorBoundary';
import Routes from 'features/routes/Routes';

import { theme } from './theme';

message.config({ duration: DEFAULT_MESSAGE_DURATION });

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: { retry: 0 },
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const App = (): JSX.Element => (
  <StrictMode>
    <ConfigProvider theme={theme}>
      <AntdApp>
        <ErrorBoundary>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </AntdApp>
    </ConfigProvider>
  </StrictMode>
);

export default App;
