import type { JSX, PropsWithChildren } from 'react';

import Layout from 'antd/lib/layout';

import Footer from 'common/components/layout/Footer';

import styles from './PageLayout.module.scss';

const containerClass = `align-center justify-center ${styles.container}`;

const PageLayout = ({ children }: PropsWithChildren): JSX.Element => (
  <Layout className={containerClass}>
    <Layout.Content className="align-center flex flex-column justify-center align-center">{children}</Layout.Content>
    <Footer />
  </Layout>
);

export default PageLayout;
